import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

export default props => (
  <div className="uk-box-shadow-small">
    <Link to={props.ImageUrl}>
      <div className="uk-overflow-hidden uk-transition-toggle uk-box-shadow-hover-small">
        <Img fluid={props.ImageFluid} className="uk-transition-scale-up uk-transition-opaque" />
      </div>
    </Link>
  </div>
)
