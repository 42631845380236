import React from "react"
import { Link, graphql } from "gatsby"

import Head from "../components/head"
import Layout from "../components/layout"
import Section from "../components/section"
import Grid from "../components/grid"
// import Card from "../components/card";
import CardSkill from "../components/card-level-only"
// import CardService from "../components/card-link";
import CardService from "../components/image-title"
import ImageLink from "../components/image-link"
import ButtonTag from "../components/button-tag"

const randomSort = arr => arr.sort(() => Math.random() - 0.5)

export default ({ data }) => {
  // console.log("PREVIOUS", data.previous.frontmatter.title);
  const node = data.markdownRemark
  const services = data.services ? data.services.edges : null
  const skills = data.skills ? randomSort(data.skills.edges) : null

  return (
    <div>
      <Head
        PageTitle={node.frontmatter.title + " - " + node.frontmatter.category}
        PageDescription={node.frontmatter.subtitle + " : " + node.excerpt}
        PageSlug={node.fields.slug}
        PageImage={node.frontmatter.cover_image.childImageSharp.fluid.src}
      />

      <Layout>
        <Section Style="default">
          <div>
            <h3>
              <Link to={"/" + node.frontmatter.categoryId + "/"}>
                <small>{node.frontmatter.category}</small>
              </Link>{" "}
              <span className="uk-margin-small-right" data-uk-icon="chevron-right" /> {node.frontmatter.title}
            </h3>

            {/* <div className="uk-width-1-2@s uk-width-2-3@m uk-align-center uk-align-right@s uk-margin-remove-adjacent">
              <Lightbox ImageUrl={node.frontmatter.cover_image.childImageSharp.original.src} ImageFluid={node.frontmatter.cover_image.childImageSharp.fluid} />
            </div> */}

            <div className="uk-width-1-2@s uk-width-2-3@m uk-align-center uk-align-right@s uk-margin-remove-adjacent" data-uk-lightbox>
              <ImageLink ImageUrl={node.frontmatter.cover_image.childImageSharp.original.src} ImageFluid={node.frontmatter.cover_image.childImageSharp.fluid} />

              <div className="uk-grid-small uk-child-width-1-2 uk-padding-small uk-padding-remove-horizontal" data-uk-grid>
                {node.frontmatter.image1 && (
                  <div>
                    <ImageLink ImageUrl={node.frontmatter.image1.childImageSharp.original.src} ImageFluid={node.frontmatter.image1.childImageSharp.fluid} />
                  </div>
                )}
                {node.frontmatter.image2 && (
                  <div>
                    <ImageLink ImageUrl={node.frontmatter.image2.childImageSharp.original.src} ImageFluid={node.frontmatter.image2.childImageSharp.fluid} />
                  </div>
                )}
                {node.frontmatter.image3 && (
                  <div>
                    <ImageLink ImageUrl={node.frontmatter.image3.childImageSharp.original.src} ImageFluid={node.frontmatter.image3.childImageSharp.fluid} />
                  </div>
                )}
                {node.frontmatter.image4 && (
                  <div>
                    <ImageLink ImageUrl={node.frontmatter.image4.childImageSharp.original.src} ImageFluid={node.frontmatter.image4.childImageSharp.fluid} />
                  </div>
                )}
              </div>
            </div>

            <small>{node.frontmatter.subtitle}</small>
            <div dangerouslySetInnerHTML={{ __html: node.html }} />
            <hr />

            <p>
              <em>Date de réalisation</em> :
            </p>
            {node.frontmatter.date}

            <hr />
            <p>
              <em>Services</em> :
            </p>
            <div>
              {services &&
                services.map(({ node }) => {
                  return (
                    <ButtonTag key={node.id} To={node.fields.slug}>
                      {node.frontmatter.titleId}
                    </ButtonTag>
                  )
                })}
            </div>

            <hr />
            <p>
              <em>Compétences</em> :
            </p>
            <div>
              {skills &&
                skills.map(({ node }) => {
                  return (
                    <ButtonTag key={node.id} To={node.fields.slug}>
                      {node.frontmatter.titleId}
                    </ButtonTag>
                  )
                })}
            </div>

            {/* <ul className="uk-pagination">
            <li>
              <a href="/#">
                <span
                  className="uk-margin-small-right"
                  data-uk-pagination-previous=""
                />{" "}
                Précédent
              </a>
            </li>
            <li className="uk-margin-auto-left">
              <a href="/#">
                Suivant{" "}
                <span
                  className="uk-margin-small-left"
                  data-uk-pagination-next=""
                />
              </a>
            </li>
          </ul> */}
          </div>
        </Section>

        <Section Style="muted">
          <h4 id="skills" className="uk-text-center uk-margin-medium">
            Compétences et techologies associées
          </h4>
          <Grid Col="4">
            {skills &&
              skills.map(({ node }) => {
                return (
                  <div key={node.id}>
                    <CardSkill
                      ImageFluid={node.frontmatter.cover_image.childImageSharp.fluid}
                      Level={node.frontmatter.level}
                      Title={node.frontmatter.title}
                      // Intro={node.excerpt}
                      LinkUrl={node.fields.slug}
                      LinkText="Découvrir"
                    />
                  </div>
                )
              })}
          </Grid>
        </Section>

        <Section Style="secondary">
          <h4 id="services" className="uk-text-center uk-margin-medium uk-light">
            Services et prestations associés
          </h4>
          <Grid Col="3">
            {services &&
              services.map(({ node }) => {
                return (
                  <div key={node.id} className="uk-light">
                    <CardService ImageFluid={node.frontmatter.icon.childImageSharp.fluid} Label="" Title={node.frontmatter.title} LinkUrl={node.fields.slug} />
                  </div>
                )
              })}
          </Grid>
        </Section>
      </Layout>
    </div>
  )
}

export const query = graphql`
  query($project: String!, $services: [String], $skills: [String]) {
    markdownRemark(frontmatter: { titleId: { eq: $project } }) {
      html
      frontmatter {
        title
        subtitle
        titleId
        date(formatString: "MMMM YYYY", locale: "fr")
        cover_image {
          childImageSharp {
            original {
              src
            }
            fluid(maxWidth: 848, maxHeight: 480) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image1 {
          childImageSharp {
            original {
              src
            }
            fluid(maxWidth: 848, maxHeight: 480) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image2 {
          childImageSharp {
            original {
              src
            }
            fluid(maxWidth: 848, maxHeight: 480) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image3 {
          childImageSharp {
            original {
              src
            }
            fluid(maxWidth: 848, maxHeight: 480) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image4 {
          childImageSharp {
            original {
              src
            }
            fluid(maxWidth: 848, maxHeight: 480) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        category
        categoryId
        services
        skills
      }
      fields {
        slug
      }
      excerpt(pruneLength: 320)
    }
    services: allMarkdownRemark(sort: { fields: [frontmatter___date], order: ASC }, filter: { frontmatter: { titleId: { in: $services } } }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            titleId
            icon {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date(formatString: "YYYY")
            category
            categoryId
            services
            skills
          }
          fields {
            slug
          }
          excerpt(pruneLength: 80)
        }
      }
    }
    skills: allMarkdownRemark(sort: { fields: [frontmatter___date], order: ASC }, filter: { frontmatter: { titleId: { in: $skills } } }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            titleId
            cover_image {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 225) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date(formatString: "YYYY")
            category
            categoryId
            services
            skills
            level
          }
          fields {
            slug
          }
          excerpt(pruneLength: 80)
        }
      }
    }
  }
`
